<template>
  <section class="login">
  </section>
</template>

<script>
import organizationApi from "@/apis/organization";

export default {
  created() {
    this.fetchOrganization()
  },
  methods: {
    async fetchOrganization() {
      await organizationApi
        .getOrganizationByShoplineId({ shopline_merchant_id: this.$route.query.merchant_id })
        .then((response) => {
          console.log('response', response)
          this.$router.push({
            name: "AuthLogin",
            params: { org_code: response.data.data.code },
          });
        })
        .catch((error) => {
          console.error(error);
          this.$swal("失敗", error.response.data.message, "error").then(() => {
            this.$router.push({ name: 'Error404' })
          });
        });
    },
  },
};
</script>
